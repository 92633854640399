import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {CdkDragEnd} from '@angular/cdk/drag-drop';

export interface ILocation {
  x: number;
  y: number;
}

export interface IPlayer extends ILocation {
  id: string;
  name: string;
  displayName: string;
}

export interface IRunner extends IPlayer {
  show: boolean;
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.pug',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements AfterViewInit {

  debug = false;

  @ViewChild('svg') svg: ElementRef;
  @ViewChild('deepLeftField') deepLeftField: ElementRef<SVGGeometryElement>;
  @ViewChild('deepCenterField') deepCenterField: ElementRef<SVGGeometryElement>;
  @ViewChild('deepRightField') deepRightField: ElementRef<SVGGeometryElement>;
  @ViewChild('shallowLeftField') shallowLeftField: ElementRef<SVGGeometryElement>;
  @ViewChild('shallowCenterField') shallowCenterField: ElementRef<SVGGeometryElement>;
  @ViewChild('shallowRightField') shallowRightField: ElementRef<SVGGeometryElement>;
  baseballPoint: SVGPoint;

  playToRun = '';
  moveMillisecondTiming = 30;
  runnerCircleRadius = 16;
  runnerCircleColor = '#ea1740';
  runnerTextColor = '#01d5ea';
  thirdBaseman: IPlayer;
  secondBaseman: IPlayer;
  firstBaseman: IPlayer;
  shortstop: IPlayer;
  pitcher: IPlayer;
  catcher: IPlayer;
  leftFielder: IPlayer;
  centerFielder: IPlayer;
  rightFielder: IPlayer;
  hitter: IRunner;
  runnerOnFirst: IRunner;
  runnerOnSecond: IRunner;
  runnerOnThird: IRunner;
  baseball: ILocation = {x: 647, y: 810};
  firstBase: ILocation = {x: 887, y: 663};
  secondBase: ILocation = {x: 657, y: 402};
  thirdBase: ILocation = {x: 427, y: 632};
  homePlate: ILocation = {x: 654, y: 859};

  constructor() {
    this.resetPlayers();
    this.resetBaseball();
  }

  ngAfterViewInit(): void {
    this.baseballPoint = this.svg.nativeElement.createSVGPoint();
  }

  resetField(): void {
    this.resetPlayers();
    this.resetBaseball();
    this.playToRun = '';
  }

  resetBaseball(): void {
    this.baseball = {x: 647, y: 810};
  }

  resetPlayers(): void {
    this.thirdBaseman = {id: 'player-3b', name: 'Third Baseman', displayName: '3B', x: 430, y: 530};
    this.secondBaseman = {id: 'player-2b', name: 'Second Baseman', displayName: '2B', x: 780, y: 420};
    this.firstBaseman = {id: 'player-1b', name: 'First Baseman', displayName: '1B', x: 885, y: 530};
    this.shortstop = {id: 'player-ss', name: 'Short Stop', displayName: 'SS', x: 530, y: 420};
    this.pitcher = {id: 'player-p', name: 'Pitcher', displayName: 'P', x: 657, y: 640};
    this.catcher = {id: 'player-c', name: 'Catcher', displayName: 'C', x: 655, y: 910};
    this.leftFielder = {id: 'player-lf', name: 'Left Fielder', displayName: 'LF', x: 325, y: 300};
    this.centerFielder = {id: 'player-cf', name: 'Center Fielder', displayName: 'CF', x: 655, y: 175};
    this.rightFielder = {id: 'player-rf', name: 'Right Fielder', displayName: 'RF', x: 975, y: 300};
    this.hitter = {id: 'hitter', name: 'Hitter', displayName: 'R', x: 615, y: 860, show: true};
    this.runnerOnFirst = {id: 'runner-1', name: 'Runner On 1st', displayName: 'R', x: 875, y: 620, show: false};
    this.runnerOnSecond = {id: 'runner-2', name: 'Runner On 2nd', displayName: 'R', x: 645, y: 410, show: false};
    this.runnerOnThird = {id: 'runner-3', name: 'Runner On 3rd', displayName: 'R', x: 440, y: 640, show: false};
  }

  play(): void {
    // TODO
    if (this.ballInFieldLocation(this.deepLeftField)) {
      this.ballToDeepLeftField();
    } else if (this.ballInFieldLocation(this.deepCenterField)) {
      this.ballToDeepCenterField();
    } else if (this.ballInFieldLocation(this.deepRightField)) {
      this.ballToDeepRightField();
    } else if (this.ballInFieldLocation(this.shallowLeftField)) {
      this.ballToShallowLeftField();
    } else if (this.ballInFieldLocation(this.shallowCenterField)) {
      this.ballToShallowCenterField();
    } else if (this.ballInFieldLocation(this.shallowRightField)) {
      this.ballToShallowRightField();
    }
  }

  ballToDeepLeftField(): void {
    if (this.noRunnersOn() || this.runnerOnThirdOnly()) {
      this.playToRun = 'Cut-2';
      this.movePlayer(this.leftFielder, this.baseballPoint.x, this.baseballPoint.y, this.leftFielder.x, this.leftFielder.y, 1, 100, this.shortstop, this.moveBaseball);
      const shortStopPoint: ILocation = this.getClosestPointOnLine({x: 475, y: 290}, [this.secondBase, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.shortstop, shortStopPoint.x, shortStopPoint.y);
      this.movePlayer(this.firstBaseman, 865, 620);
      this.movePlayer(this.thirdBaseman, 420, 615);
      this.movePlayer(this.secondBaseman, 675, 400);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 800, 475);
      this.movePlayer(this.rightFielder, 990, 460);
      this.movePlayer(this.centerFielder, 200, 25);
      this.moveRunners(true);
    } else if (this.runnerOnFirstOnly() || this.runnersOnFirstAndThirdOnly()) {
      this.playToRun = 'Cut-3';
      this.movePlayer(this.leftFielder, this.baseballPoint.x, this.baseballPoint.y, this.leftFielder.x, this.leftFielder.y, 1, 100, this.shortstop, this.moveBaseball);
      const shortStopPoint: ILocation = this.getClosestPointOnLine({x: 320, y: 430}, [this.thirdBase, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.shortstop, shortStopPoint.x, shortStopPoint.y);
      this.movePlayer(this.firstBaseman, 865, 620);
      this.movePlayer(this.thirdBaseman, 420, 615);
      this.movePlayer(this.secondBaseman, 675, 400);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 485, 760);
      this.movePlayer(this.rightFielder, 825, 300);
      this.movePlayer(this.centerFielder, 200, 25);
      this.moveRunners(true);
    } else if (this.runnersOnFirstAndSecondOnly() || this.runnerOnSecondOnly() || this.runnersOnSecondAndThirdOnly() || this.basesLoaded()) {
      this.playToRun = 'Cut-4';
      this.movePlayer(this.leftFielder, this.baseballPoint.x, this.baseballPoint.y, this.leftFielder.x, this.leftFielder.y, 1, 100, this.thirdBaseman, this.moveBaseball);
      const thirdBasemanPoint: ILocation = this.getClosestPointOnLine({x: 475, y: 600}, [this.homePlate, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.thirdBaseman, thirdBasemanPoint.x, thirdBasemanPoint.y);
      this.movePlayer(this.shortstop, 420, 615);
      this.movePlayer(this.firstBaseman, 865, 620);
      this.movePlayer(this.secondBaseman, 675, 400);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 700, 975);
      this.movePlayer(this.rightFielder, 825, 300);
      this.movePlayer(this.centerFielder, 200, 25);
      this.moveRunners(true);
    }
  }

  ballToShallowLeftField(): void {
    this.ballToDeepLeftField();
  }

  ballToDeepCenterField(): void {
    if (this.noRunnersOn() || this.runnerOnThirdOnly()) {
      this.playToRun = 'Cut-2';
      this.movePlayer(this.centerFielder, this.baseballPoint.x, this.baseballPoint.y, this.centerFielder.x, this.centerFielder.y, 1, 100, this.shortstop, this.moveBaseball);
      const shortStopPoint: ILocation = this.getClosestPointOnLine({x: 650, y: 260}, [this.secondBase, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.shortstop, shortStopPoint.x, shortStopPoint.y);
      this.movePlayer(this.firstBaseman, 890, 620);
      this.movePlayer(this.thirdBaseman, 420, 615);
      this.movePlayer(this.secondBaseman, 675, 400);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 675, 540);
      this.movePlayer(this.rightFielder, 790, 15);
      this.movePlayer(this.leftFielder, 500, 15);
    } else if (this.runnerOnFirstOnly() || this.runnersOnFirstAndThirdOnly()) {
      this.playToRun = 'Cut-3';
      this.movePlayer(this.centerFielder, this.baseballPoint.x, this.baseballPoint.y, this.centerFielder.x, this.centerFielder.y, 1, 100, this.shortstop, this.moveBaseball);
      const shortStopPoint: ILocation = this.getClosestPointOnLine({x: 550, y: 375}, [this.thirdBase, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.shortstop, shortStopPoint.x, shortStopPoint.y);
      this.movePlayer(this.firstBaseman, 890, 620);
      this.movePlayer(this.thirdBaseman, 420, 615);
      this.movePlayer(this.secondBaseman, 675, 400);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 375, 750);
      this.movePlayer(this.rightFielder, 790, 15);
      this.movePlayer(this.leftFielder, 500, 15);
    } else if (this.runnersOnFirstAndSecondOnly() || this.runnerOnSecondOnly() || this.runnersOnSecondAndThirdOnly() || this.basesLoaded()) {
      this.playToRun = 'Cut-4';
      this.movePlayer(this.centerFielder, this.baseballPoint.x, this.baseballPoint.y, this.centerFielder.x, this.centerFielder.y, 1, 100, this.firstBaseman, this.moveBaseball);
      const firstBasemanPoint: ILocation = this.getClosestPointOnLine({x: 650, y: 550}, [this.homePlate, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.firstBaseman, firstBasemanPoint.x, firstBasemanPoint.y);
      this.movePlayer(this.shortstop, 675, 400);
      this.movePlayer(this.thirdBaseman, 420, 615);
      this.movePlayer(this.secondBaseman, 890, 620);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 650, 970);
      this.movePlayer(this.rightFielder, 790, 15);
      this.movePlayer(this.leftFielder, 500, 15);
    }

    this.moveRunners();
  }

  ballToShallowCenterField(): void {
    this.ballToDeepCenterField();
  }

  ballToDeepRightField(): void {
    if (this.noRunnersOn() || this.runnerOnThirdOnly()) {
      this.playToRun = 'Cut-2';
      this.movePlayer(this.rightFielder, this.baseballPoint.x, this.baseballPoint.y, this.rightFielder.x, this.rightFielder.y, 1, 100, this.secondBaseman, this.moveBaseball);
      const secondBasemanPoint: ILocation = this.getClosestPointOnLine({x: 850, y: 300}, [this.secondBase, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.secondBaseman, secondBasemanPoint.x, secondBasemanPoint.y);
      this.movePlayer(this.firstBaseman, 865, 620);
      this.movePlayer(this.thirdBaseman, 420, 615);
      this.movePlayer(this.shortstop, 675, 400);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 530, 465);
      this.movePlayer(this.leftFielder, 350, 400);
      this.movePlayer(this.centerFielder, 1160, 30);
    } else if (this.runnerOnFirstOnly() || this.runnersOnFirstAndThirdOnly()) {
      this.playToRun = 'Cut-3';
      this.movePlayer(this.rightFielder, this.baseballPoint.x, this.baseballPoint.y, this.rightFielder.x, this.rightFielder.y, 1, 100, this.shortstop, this.moveBaseball);
      const shortstopPoint: ILocation = this.getClosestPointOnLine({x: 640, y: 465}, [this.thirdBase, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.shortstop, shortstopPoint.x, shortstopPoint.y);
      this.movePlayer(this.firstBaseman, 890, 620);
      this.movePlayer(this.thirdBaseman, 420, 615);
      this.movePlayer(this.secondBaseman, 675, 400);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 265, 700);
      this.movePlayer(this.leftFielder, 360, 390);
      this.movePlayer(this.centerFielder, 1160, 30);
    } else if (this.runnersOnFirstAndSecondOnly() || this.runnerOnSecondOnly() || this.runnersOnSecondAndThirdOnly() || this.basesLoaded()) {
      this.playToRun = 'Cut-4';
      this.movePlayer(this.rightFielder, this.baseballPoint.x, this.baseballPoint.y, this.rightFielder.x, this.rightFielder.y, 1, 100, this.firstBaseman, this.moveBaseball);
      const firstBasemanPoint: ILocation = this.getClosestPointOnLine({x: 760, y: 620}, [this.homePlate, {x: this.baseballPoint.x, y: this.baseballPoint.y}]);
      this.movePlayer(this.firstBaseman, firstBasemanPoint.x, firstBasemanPoint.y);
      this.movePlayer(this.secondBaseman, 890, 620);
      this.movePlayer(this.thirdBaseman, 420, 615);
      this.movePlayer(this.shortstop, 675, 400);
      this.movePlayer(this.catcher, 640, 845);
      this.movePlayer(this.pitcher, 580, 980);
      this.movePlayer(this.leftFielder, 240, 645);
      this.movePlayer(this.centerFielder, 1160, 30);
    }

    this.moveRunners();
  }

  ballToShallowRightField(): void {
    this.ballToDeepRightField();
  }

  moveRunners(oneExtraBase?: boolean): void {
    this.movePlayer(this.hitter, 886, 634);
    if (this.runnerOnFirst.show) {
      this.movePlayer(this.runnerOnFirst, 657, 400);
    }
    if (this.runnerOnSecond.show) {
      this.movePlayer(this.runnerOnSecond, 425, 634);
    }
    if (this.runnerOnThird.show) {
      this.movePlayer(this.runnerOnThird, 674, 879);
    }
  }

  moveBaseball = (throwToPlayer: IPlayer, startX: number = this.baseball.x, startY: number = this.baseball.y, currentPercentOnLine: number = 1, maxPercentOnLine: number = 100): void => {
    setTimeout(() => {
      this.moveToNewLocation(this.baseball, startX, startY, throwToPlayer.x, throwToPlayer.y, currentPercentOnLine / 100);
      this.baseball = {x: this.baseball.x, y: this.baseball.y};
      if (currentPercentOnLine !== maxPercentOnLine) {
        this.moveBaseball(throwToPlayer, startX, startY, currentPercentOnLine += 3, maxPercentOnLine);
      }
    }, this.moveMillisecondTiming);
  }

  movePlayer(player: IPlayer, endX: number, endY: number, startX: number = player.x, startY: number = player.y, currentPercentOnLine: number = 1, maxPercentOnLine: number = 100, throwToPlayer?: IPlayer, moveBaseball?: (throwToPlayer: IPlayer) => void): void {
    setTimeout(() => {
      this.moveToNewLocation(player, startX, startY, endX, endY, currentPercentOnLine / 100);
      if (currentPercentOnLine !== maxPercentOnLine) {
        this.movePlayer(player, endX, endY, startX, startY, currentPercentOnLine += 1, maxPercentOnLine, throwToPlayer, moveBaseball);
      } else if (moveBaseball !== undefined && throwToPlayer !== undefined) {
        moveBaseball(throwToPlayer);
      }
    }, this.moveMillisecondTiming);
  }

  moveToNewLocation(location: ILocation, startX: number, startY: number, endX: number, endY: number, percentage: number): void {
    const newPoint = this.getPointAlongLine(startX, startY, endX, endY, percentage);
    location.x = newPoint.x;
    location.y = newPoint.y;
  }

  getPointAlongLine(x1: number, y1: number, x2: number, y2: number, percentage: number): any {
    return {x: (x1 * (1.0 - percentage) + x2 * percentage), y: (y1 * (1.0 - percentage) + y2 * percentage)};
  }

  toggleShow(runner: IRunner): void {
    runner.show = !runner.show;
  }

  canShow(runner: IRunner): boolean {
    return runner.show;
  }

  ballInFieldLocation(location: ElementRef<SVGGeometryElement>): boolean {
    return location.nativeElement.isPointInFill(this.baseballPoint);
  }

  baseballDragEnd($event: CdkDragEnd): void {
    this.baseball.x = $event.source.getFreeDragPosition().x;
    this.baseball.y = $event.source.getFreeDragPosition().y;
    this.baseballPoint.x = this.baseball.x;
    this.baseballPoint.y = this.baseball.y;
  }

  noRunnersOn(): boolean {
    return !this.runnerOnFirst.show && !this.runnerOnSecond.show && !this.runnerOnThird.show;
  }

  runnerOnFirstOnly(): boolean {
    return this.runnerOnFirst.show && !this.runnerOnSecond.show && !this.runnerOnThird.show;
  }

  runnerOnSecondOnly(): boolean {
    return !this.runnerOnFirst.show && this.runnerOnSecond.show && !this.runnerOnThird.show;
  }

  runnerOnThirdOnly(): boolean {
    return !this.runnerOnFirst.show && !this.runnerOnSecond.show && this.runnerOnThird.show;
  }

  runnersOnFirstAndSecondOnly(): boolean {
    return this.runnerOnFirst.show && this.runnerOnSecond.show && !this.runnerOnThird.show;
  }

  runnersOnFirstAndThirdOnly(): boolean {
    return this.runnerOnFirst.show && !this.runnerOnSecond.show && this.runnerOnThird.show;
  }

  runnersOnSecondAndThirdOnly(): boolean {
    return !this.runnerOnFirst.show && this.runnerOnSecond.show && this.runnerOnThird.show;
  }

  basesLoaded(): boolean {
    return this.runnerOnFirst.show && this.runnerOnSecond.show && this.runnerOnThird.show;
  }

  getClosestPointOnLine(point: ILocation, linePoints: ILocation[]): ILocation {
    let minDist;
    let fTo = 1;
    let fFrom;
    let x = 0;
    let y = 0;
    let i = 0;
    let dist;

    if (linePoints.length > 1) {
      for (let n = 1 ; n < linePoints.length ; n++) {
        if (linePoints[n].x !== linePoints[n - 1].x) {
          const a = (linePoints[n].y - linePoints[n - 1].y) / (linePoints[n].x - linePoints[n - 1].x);
          const b = linePoints[n].y - a * linePoints[n].x;
          dist = Math.abs(a * point.x + b - point.y) / Math.sqrt(a * a + 1);
        } else {
          dist = Math.abs(point.x - linePoints[n].x);
        }

        // length^2 of line segment
        const rl2 = Math.pow(linePoints[n].y - linePoints[n - 1].y, 2) + Math.pow(linePoints[n].x - linePoints[n - 1].x, 2);

        // distance^2 of pt to end line segment
        const ln2 = Math.pow(linePoints[n].y - point.y, 2) + Math.pow(linePoints[n].x - point.x, 2);

        // distance^2 of pt to begin line segment
        const lnm12 = Math.pow(linePoints[n - 1].y - point.y, 2) + Math.pow(linePoints[n - 1].x - point.x, 2);

        // minimum distance^2 of pt to infinite line
        const dist2 = Math.pow(dist, 2);

        // calculated length^2 of line segment
        const calcrl2 = ln2 - dist2 + lnm12 - dist2;

        // redefine minimum distance to line segment (not infinite line) if necessary
        if (calcrl2 > rl2) {
          dist = Math.sqrt(Math.min(ln2, lnm12));
        }

        if ((minDist == null) || (minDist > dist)) {
          if (calcrl2 > rl2) {
            if (lnm12 < ln2) {
              fTo = 0; // nearer to previous point
              fFrom = 1;
            } else {
              fFrom = 0; // nearer to current point
              fTo = 1;
            }
          } else {
            // perpendicular from point intersects line segment
            fTo = ((Math.sqrt(lnm12 - dist2)) / Math.sqrt(rl2));
            fFrom = ((Math.sqrt(ln2 - dist2)) / Math.sqrt(rl2));
          }
          minDist = dist;
          i = n;
        }
      }

      const dx = linePoints[i - 1].x - linePoints[i].x;
      const dy = linePoints[i - 1].y - linePoints[i].y;

      x = linePoints[i - 1].x - (dx * fTo);
      y = linePoints[i - 1].y - (dy * fTo);

    }

    return {x, y};
  }

}
